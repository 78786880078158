import type { OpeningHoursOverride } from '@/domain/opening-hours-override';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const OpeningHoursOverrideService = {
  findById: getAxiosFetcher<OpeningHoursOverride, [number]>((id) => [
    `${api.OPENING_HOURS_OVERRIDE}/${id}`,
  ]),

  search: getAxiosFetcher<OpeningHoursOverride[], [Partial<OpeningHoursOverride>]>((params) => [
    api.OPENING_HOURS_OVERRIDE,
    { params },
  ]),

  create: (openingHoursOverride: Partial<OpeningHoursOverride>) =>
    crudService.post<OpeningHoursOverride>(openingHoursOverride, `${api.OPENING_HOURS_OVERRIDE}`),

  update: (openingHoursOverride: Partial<OpeningHoursOverride>) =>
    crudService.put<OpeningHoursOverride>(
      openingHoursOverride,
      `${api.OPENING_HOURS_OVERRIDE}/${openingHoursOverride.id}`,
    ),

  delete: (openingHoursOverride: Partial<OpeningHoursOverride>) =>
    crudService.delete<void>(`${api.OPENING_HOURS_OVERRIDE}/${openingHoursOverride.id}`),
};
