import type { OpeningHours } from '@/domain/opening-hours';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const OpeningHoursService = {
  findById: getAxiosFetcher<OpeningHours, [number]>((id) => [`${api.OPENING_HOURS}/${id}`]),

  search: getAxiosFetcher<OpeningHours[], [Partial<OpeningHours>]>((params) => [
    api.OPENING_HOURS,
    { params },
  ]),

  create: (openingHours: Partial<OpeningHours>) =>
    crudService.post<OpeningHours>(openingHours, `${api.OPENING_HOURS}`),

  update: (openingHours: Partial<OpeningHours>) =>
    crudService.put<OpeningHours>(openingHours, `${api.OPENING_HOURS}/${openingHours.id}`),

  delete: (openingHours: Partial<OpeningHours>) =>
    crudService.delete<void>(`${api.OPENING_HOURS}/${openingHours.id}`),
};
