import type { DefaultSettings } from './defaultSettings';
import defaultSettings from './defaultSettings';

export interface SettingsModelType {
  namespace: 'settings';
  state: DefaultSettings;
}

const SettingsModel: SettingsModelType = {
  namespace: 'settings',
  state: defaultSettings,
};

export default SettingsModel;
