import type { Company } from '@/domain/company';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';

export const CompanyService = {
  findById: getAxiosFetcher<Company, [number]>((id) => [`${api.COMPANY}/${id}`]),

  search: getAxiosFetcher<Company[], [Partial<Company>]>((params) => [api.COMPANY, { params }]),

  create: (company: Partial<Company>) => crudService.post<Company>(company, api.COMPANY),

  update: (company: Partial<Company>) =>
    crudService.put<Company>(company, `${api.COMPANY}/${company.id}`),
};
