import type { Office } from '@/domain/office';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export const OfficeService = {
  findById: getAxiosFetcher<Office, [number, number]>((companyId, id) => [
    `${api.COMPANY}/${companyId}/offices/${id}`,
  ]),

  search: getAxiosFetcher<Office[], [number]>((companyId) => [
    `${api.COMPANY}/${companyId}/offices`,
  ]),

  create: (companyId: number, office: Partial<Office>) =>
    crudService.post<Office>(office, `${api.COMPANY}/${companyId}/offices`),

  update: (companyId: number, office: Partial<Office>) =>
    crudService.put<Office>(office, `${api.COMPANY}/${companyId}/offices/${office.id}`),

  patch: (companyId: number, officeId: number, operations: Operation[], version: number) =>
    crudService.patch<Office>(
      operations,
      `${api.COMPANY}/${companyId}/offices/${officeId}?version=${version}`,
    ),

  delete: (companyId: number, office: Partial<Office>) =>
    crudService.delete<void>(`${api.COMPANY}/${companyId}/offices/${office.id}`),
};
