import type { Form, FormAnswers, FormSearchParams } from '@/domain/form';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export const FormService = {
  findById: getAxiosFetcher<Form, [number]>((id) => [`${api.FORMS}/${id}`]),

  search: getAxiosFetcher<Form[], [FormSearchParams]>((params = {}) => [api.FORMS, { params }]),

  create: (form: Partial<Form>) => crudService.post<Form>(form, api.FORMS),

  update: (form: Partial<Form>) => crudService.put<Form>(form, `${api.FORMS}/${form.id}`),

  getAnswers: getAxiosFetcher<FormAnswers, [number, { entityId: number | string }]>(
    (id, params) => [`${api.FORMS}/${id}/answers`, { params }],
  ),

  updateAnswers: (id: number, answers: FormAnswers) =>
    crudService.post<void>(answers, `${api.FORMS}/${id}/answers`),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<Form>(operations, `${api.FORMS}/${id}?version=${version}`),

  delete: (form: Partial<Form>) => crudService.delete<void>(`${api.FORMS}/${form.id}`),
};
