import type { EmailTemplate, EmailTemplateSearchParams } from '@/domain/email-template';
import type { EmailBody } from '@/domain/emails';
import { api } from '@/utils/configuration';
import { crudService, getAxiosFetcher } from '@/utils/request';
import type { Operation } from 'fast-json-patch';

export const EmailTemplatesService = {
  findById: getAxiosFetcher<EmailTemplate, [number]>((id) => [`${api.EMAIL_TEMPLATES}/${id}`]),

  search: getAxiosFetcher<EmailTemplate[], [Partial<EmailTemplate | EmailTemplateSearchParams>]>(
    (params = {}) => [api.EMAIL_TEMPLATES, { params }],
  ),

  create: (template: Partial<EmailTemplate>) =>
    crudService.post<EmailTemplate>(template, `${api.EMAIL_TEMPLATES}`),

  update: (template: Partial<EmailTemplate>) =>
    crudService.put<EmailTemplate>(template, `${api.EMAIL_TEMPLATES}/${template.id}`),

  patch: (id: number, operations: Operation[], version: number) =>
    crudService.patch<EmailTemplate>(operations, `${api.EMAIL_TEMPLATES}/${id}?version=${version}`),

  delete: (template: Partial<EmailTemplate>) =>
    crudService.delete<void>(`${api.EMAIL_TEMPLATES}/${template.id}`),

  fill: (emailTemplateId: number, entityId: number) =>
    crudService.post<EmailBody>({ entityId }, `${api.EMAIL_TEMPLATES}/${emailTemplateId}/fill`),
};
