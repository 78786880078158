import { EnvironmentService } from '@/services/environment';
import type { BrowserOptions } from '@sentry/react';
import {
  BrowserTracing,
  captureException,
  init,
  reactRouterV6Instrumentation,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

const options: BrowserOptions = {
  dsn: 'https://8f8b588bd6914bd08618bbdc649fbf2e@o372675.ingest.sentry.io/5896649',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
};

export const SentryService = {
  init: (config: BrowserOptions = {}) => {
    const isProd = process.env.NODE_ENV === 'production';
    const release = process.env.RELEASE_NAME;

    if (isProd && release) {
      EnvironmentService.getEnvironment().then((environment) => {
        if (environment === 'stage' || environment === 'production') {
          init({
            ...options,
            ...config,
            environment,
            release,
          });
        }
      });
    }
  },
  trackError: captureException,
  sentryCreateBrowserRouter: wrapCreateBrowserRouter(createBrowserRouter),
};
