import { setAccessToken, setRefreshToken } from '@/utils/authority';
import { api } from '@/utils/configuration';
import { crudService } from '@/utils/request';

export interface RefreshTokenRequest {
  refreshToken: string;
}

export interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export const AuthService = {
  requestPasswordChange: (emailAddress: string, returnTo: string): Promise<void> =>
    crudService.post<void>({}, `${api.AUTH}/password`, { params: { emailAddress, returnTo } }),

  refreshToken: (refreshTokenRequest: RefreshTokenRequest) =>
    crudService
      .post<RefreshTokenResponse>(refreshTokenRequest, `${api.AUTH}/refresh`)
      .then((response) => {
        setAccessToken(response.accessToken);
        setRefreshToken(response.refreshToken);
        return response;
      }),
};
